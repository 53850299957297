define('ember-classy-computed/index', ['exports', 'ember', 'ember-weakmap'], function (exports, _ember, _emberWeakmap) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var EmberObject = _ember['default'].Object;
  var computed = _ember['default'].computed;
  var A = _ember['default'].A;
  var isNone = _ember['default'].isNone;
  var getOwner = _ember['default'].getOwner;

  var PROPERTIES = new _emberWeakmap['default']();

  function findOrCreatePropertyInstance(propertyClass, context, key) {
    var propertiesForContext = PROPERTIES.get(context);
    if (isNone(propertiesForContext)) {
      propertiesForContext = {};
      PROPERTIES.set(context, propertiesForContext);
    }

    var property = propertiesForContext[key];
    if (property) {
      return property;
    }

    var owner = getOwner(context);
    property = propertyClass.create(owner.ownerInjection(), {
      _key: key,
      _context: context
    });

    propertiesForContext[key] = property;
    return property;
  }

  var ClassBasedComputedProperty = EmberObject.extend({
    _context: null,
    _key: null,

    invalidate: function invalidate() {
      this._context.notifyPropertyChange(this._key);
    }
  });

  ClassBasedComputedProperty.reopenClass({
    property: function property(klass) {
      return function () {
        for (var _len = arguments.length, dependencies = Array(_len), _key = 0; _key < _len; _key++) {
          dependencies[_key] = arguments[_key];
        }

        return computed.apply(undefined, dependencies.concat([function (key) {
          var _this = this;

          var property = findOrCreatePropertyInstance(klass, this, key);

          var values = A(dependencies).map(function (dep) {
            return _this.get(dep);
          });
          return property.compute.apply(property, _toConsumableArray(values));
        }]));
      };
    }
  });

  exports['default'] = ClassBasedComputedProperty;
});