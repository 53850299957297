define('ember-awesome-macros/array/group-by', ['exports', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key', 'ember-macro-helpers/create-class-computed'], function (exports, _computed, _normalizeArrayKey, _createClassComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getCurrentGroup(groups, value, comparator) {
    if (comparator) {
      return groups.find(function (group) {
        return comparator(Ember.get(group, 'value'), value);
      });
    }

    return groups.findBy('value', value);
  }

  exports.default = (0, _createClassComputed.default)([false, true], function (array, key, comparator) {
    return (0, _computed.default)((0, _normalizeArrayKey.default)(array, [key]), comparator, function (array, comparator) {
      if (!array || !key) {
        return array;
      }

      var groups = Ember.A();
      array.forEach(function (item) {
        var value = Ember.get(item, key);
        var currentGroup = getCurrentGroup(groups, value, comparator);

        if (currentGroup) {
          currentGroup.items.push(item);
        } else {
          groups.push({
            key: key,
            value: value,
            items: [item]
          });
        }
      });

      return groups;
    });
  });
});