define('ember-awesome-macros/array/filter', ['exports', 'ember-awesome-macros/array/-utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utils.normalizeArray)({ defaultValue: function defaultValue() {
      return [];
    } }, function (array, callback) {
    return array.filter(callback);
  });
});