define('ember-pusher/services/pusher', ['exports', 'ember', 'ember-pusher/compat', 'npm:pusher-js'], function (exports, _ember, _emberPusherCompat, _npmPusherJs) {

  // Need to track
  // 1) channel object
  // 2) event bindings which consist of
  //    - handler
  //    - event name
  //    - a unique string representing the target
  //
  //  bindings: {
  //    'channel-one': {
  //      channel: Pusher.Channel,
  //      eventBindings: {
  //        Ember.Route.toString(): [
  //          { handler: Function, eventName: String },
  //          { handler: Function, eventName: String }
  //        ]
  //      }
  //    }
  //  }
  //
  //  wire(target, channelName, events)
  //  ================
  //  Initialize object in bindings if it's empty, with eventBindings: {}
  //  If eventBindings.length for the current target is 0
  //    connect to the channel
  //    store channel in the hash
  //  For each event in events
  //    bind the channel to the eventName
  //    store the handler and eventName in the eventBindings array for this channel and controller
  //    the key for storing is in target._pusherTargetId()
  //    (we store the eventName for when we might want to programmatically unwire)
  //
  //
  //  unwire(route):
  //  =================
  //  get the channel object
  //  for each  handler, eventName in eventBindings for the current route
  //    call channel.unbind(eventName, handler)
  //  delete the routes record in EventBindings
  //  if eventBindings for this channel is empty
  //    unsubscribe from the channel
  //    delete the channel from bindings

  exports['default'] = _ember['default'].Service.extend({
    isDisconnected: true,
    isConnected: _ember['default'].computed.not('isDisconnected'),

    init: function init() {
      this.pusher = null;
      this.set('bindings', {});
      this.logEvents = false;
    },

    setup: function setup(applicationKey, options) {
      _ember['default'].assert("ember-pusher can only be setup once", !this.pusher);

      this.pusher = new _npmPusherJs['default'](applicationKey, options);
      this.pusher.connection.bind('connected', this._didConnect.bind(this));
      this.pusher.connection.bind('disconnected', this._didDisconnect.bind(this));
      this.pusher.connection.bind('unavailable', this._didDisconnect.bind(this));
    },

    willDestroy: function willDestroy() {
      if (this.pusher) {
        this.pusher.disconnect();
      }
    },

    // If you have re-connected pusher, you will probably
    // want to rewire all of the previous bindings
    rewire: function rewire() {
      var bindings = this.get('bindings'),
          channelNames = Object.keys(bindings);

      for (var i = 0; i < channelNames.length; i++) {
        var channelName = channelNames[i];
        var contextObjects = Object.keys(bindings[channelName].eventBindings);

        for (var j = 0; j < contextObjects.length; j++) {
          var contextObject = contextObjects[j];
          var events = bindings[channelName].eventBindings[contextObject].map(function (i) {
            return i.eventName;
          });
          var target = bindings[channelName].eventBindings[contextObject][0].target;
          this.wire(target, channelName, events);
        }
      }
    },

    // @events a hash in the form { channel-name: ['event1', 'event2'] }
    // @target any object that responds to send() and _pusherEventsId()
    wire: function wire(target, channelName, events) {
      _ember['default'].assert("Did you forget to extend the EmberPusher.Bindings mixin in " + "your class receiving events?", !!target._pusherEventsId);

      var channel = this.connectChannel(channelName),
          bindings = this.get('bindings'),
          targetId = target._pusherEventsId();

      if (typeof events === 'string') {
        events = [events];
      }

      // Setup the eventBindings array for this target
      if (!bindings[channelName].eventBindings[targetId]) {
        bindings[channelName].eventBindings[targetId] = [];
      }

      // Iterate over the events and bind them
      events.forEach(function (eventName) {
        var normalizedEventName = _ember['default'].String.camelize(eventName);
        var events = bindings[channelName].eventBindings[targetId];
        var found = undefined;
        var handler = function handler(data) {
          if (target.get('logPusherEvents')) {
            console.log(target.constructor.toString() + ": Pusher event received", eventName, data);
          }
          _ember['default'].run(function () {
            target.send(normalizedEventName, data);
          });
        };

        channel.bind(eventName, handler);

        if (found = events.findBy('eventName', eventName)) {
          found.handler = handler;
        } else {
          events.pushObject({
            handler: handler,
            eventName: eventName,
            target: target
          });
        }
      });
    },

    connectChannel: function connectChannel(channelName) {
      var pusher = this.pusher,
          bindings = this.get('bindings');

      if (!bindings[channelName]) {
        bindings[channelName] = { eventBindings: {} };
      }

      if (_ember['default'].isEmpty((0, _emberPusherCompat.keys)(bindings[channelName].eventBindings))) {
        bindings[channelName].channel = pusher.subscribe(channelName);

        // Spit out a bunch of logging if asked
        if (this.namespace && this.logEvents) {
          bindings[channelName].channel.bind_all(function (eventName, data) {
            console.log("Pusher event received on " + channelName + ":", eventName, data);
          });
        }
      }
      return bindings[channelName].channel;
    },

    unwire: function unwire(target, channelName, eventsToUnwire) {
      var pusher = this.pusher,
          bindings = this.get('bindings'),
          targetId = target._pusherEventsId(),
          channel = bindings[channelName].channel,
          eventBindings = bindings[channelName].eventBindings[targetId];

      if (typeof eventsToUnwire === 'string') {
        eventsToUnwire = [eventsToUnwire];
      }
      var index = eventBindings.length;
      while (index--) {
        var binding = eventBindings[index];
        if (eventsToUnwire && !eventsToUnwire.contains(binding.eventName)) {
          return;
        }
        channel.unbind(binding.eventName, binding.handler);
        eventBindings.splice(index, 1);
      }

      if (_ember['default'].isEmpty(eventBindings)) {
        delete bindings[channelName].eventBindings[targetId];
      }

      // Unsubscribe from the channel if this is the last thing listening
      if ((0, _emberPusherCompat.keys)(bindings[channelName].eventBindings).length === 0) {
        pusher.unsubscribe(channelName);
        delete bindings[channelName];
        return true;
      }
      return false;
    },

    channelFor: function channelFor(channelName) {
      return this.get('bindings')[channelName].channel;
    },

    socketId: _ember['default'].computed('isDisconnected', function () {
      try {
        return this.pusher.connection.socket_id;
      } catch (error) {
        console.warn(error);
      }
    }),

    _didConnect: function _didConnect() {
      this.set('isDisconnected', false);
    },

    _didDisconnect: function _didDisconnect() {
      this.set('isDisconnected', true);
    }
  });
});