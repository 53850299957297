define('ember-file-upload/components/file-dropzone/component', ['exports', 'ember', 'ember-file-upload/components/file-dropzone/template', 'ember-file-upload/system/data-transfer', 'ember-file-upload/system/uuid'], function (exports, _ember, _emberFileUploadComponentsFileDropzoneTemplate, _emberFileUploadSystemDataTransfer, _emberFileUploadSystemUuid) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var $ = _ember['default'].$;
  var _get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var bind = _ember['default'].run.bind;
  var service = _ember['default'].inject.service;

  var DATA_TRANSFER = 'DATA_TRANSFER' + _emberFileUploadSystemUuid['default'].short();

  var supported = (function () {
    return typeof window !== 'undefined' && window.document && 'draggable' in document.createElement('span');
  })();

  var supportsHtml = null;
  var supportsUrls = null;

  exports['default'] = _ember['default'].Component.extend({

    layout: _emberFileUploadComponentsFileDropzoneTemplate['default'],

    name: null,

    supported: supported,

    ondragenter: null,

    ondragleave: null,

    fileQueue: service(),

    queue: computed('name', {
      get: function get() {
        var queueName = _get(this, 'name');
        var queues = _get(this, 'fileQueue');
        return queues.find(queueName) || queues.create(queueName);
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super();
      var id = _get(this, 'elementId');
      var handlers = this._dragHandlers = {
        dragenter: bind(this, 'didEnterDropzone'),
        dragleave: bind(this, 'didLeaveDropzone'),
        dragover: bind(this, 'didDragOver'),
        drop: bind(this, 'didDrop')
      };

      Object.keys(handlers).forEach(function (key) {
        $(document).on(key, '#' + id, handlers[key]);
      });
    },

    willDestroyElement: function willDestroyElement() {
      var id = _get(this, 'elementId');
      var handlers = this._dragHandlers || {};
      Object.keys(handlers).forEach(function (key) {
        $(document).off(key, '#' + id, handlers[key]);
      });

      this._dropzoneEntrance = null;
      this._super();
    },

    didEnterDropzone: function didEnterDropzone(_ref) {
      var evt = _ref.originalEvent;

      var element = _get(this, 'element');
      var entrance = this._dropzoneEntrance;

      if (entrance == null || $.contains(element, entrance) || element === entrance) {
        this._dropzoneEntrance = evt.target;

        var dataTransfer = _emberFileUploadSystemDataTransfer['default'].create({
          queue: _get(this, 'queue'),
          dataTransfer: evt.dataTransfer
        });
        this[DATA_TRANSFER] = dataTransfer;

        set(this, 'active', true);
        set(this, 'valid', _get(dataTransfer, 'valid'));

        if (this.ondragenter) {
          this.ondragenter(dataTransfer);
        }
      }
    },

    didLeaveDropzone: function didLeaveDropzone(_ref2) {
      var evt = _ref2.originalEvent;

      var element = _get(this, 'element');

      // If the element paired with the dragenter
      // event was removed from the DOM, clear it out
      // so the process can be run again.
      if (!$.contains(element, this._dropzoneEntrance) && element !== this._dropzoneEntrance) {
        this._dropzoneEntrance = null;
      }

      if (evt.target === this._dropzoneEntrance) {
        if (this.ondragleave) {
          set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
          this.ondragleave(this[DATA_TRANSFER]);
          this[DATA_TRANSFER] = null;
        }
        set(this, 'active', false);
        this._dropzoneEntrance = null;
      }
    },

    didDragOver: function didDragOver(_ref3) {
      var evt = _ref3.originalEvent;

      set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      evt.preventDefault();
      evt.stopPropagation();
    },

    didDrop: function didDrop(_ref4) {
      var _this = this;

      var evt = _ref4.originalEvent;

      // Testing support for dragging and dropping images
      // from other browser windows
      var html = undefined,
          url = undefined;

      if (supportsHtml === null) {
        try {
          html = evt.dataTransfer.getData('text/html');
          supportsHtml = true;
        } catch (e) {
          supportsHtml = false;
        }
      }

      if (html) {
        var img = $(html)[1];
        if (img.tagName === 'IMG') {
          url = img.src;
        }
      }

      if (supportsUrls === null) {
        try {
          url = evt.dataTransfer.getData('text/uri-list');
          supportsUrls = true;
        } catch (e) {
          supportsUrls = false;
        }
      }

      if (url) {
        var image = new Image();

        var _url$split$slice = url.split('/').slice(-1);

        var _url$split$slice2 = _slicedToArray(_url$split$slice, 1);

        var filename = _url$split$slice2[0];

        image.crossOrigin = 'anonymous';
        image.onload = function () {
          var canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;

          var ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);

          if (canvas.toBlob) {
            canvas.toBlob(function (blob) {
              var _get$_addFiles = _get(_this, 'queue')._addFiles([blob], 'web');

              var _get$_addFiles2 = _slicedToArray(_get$_addFiles, 1);

              var file = _get$_addFiles2[0];

              set(file, 'name', filename);
            });
          } else {
            var binStr = atob(canvas.toDataURL().split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            var blob = new Blob([arr], { type: 'image/png' });
            blob.name = filename;

            var _get$_addFiles3 = _get(_this, 'queue')._addFiles([blob], 'web');

            var _get$_addFiles32 = _slicedToArray(_get$_addFiles3, 1);

            var file = _get$_addFiles32[0];

            set(file, 'name', filename);
          }
        };
        image.onerror = function (e) {
          console.log(e);
        };
        image.src = url;
      }

      if (evt.preventDefault) {
        evt.preventDefault();
      }
      if (evt.stopPropagation) {
        evt.stopPropagation();
      }
      this._dropzoneEntrance = null;

      set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      if (this.ondrop) {
        this.ondrop(this[DATA_TRANSFER]);
      }

      // Add file(s) to upload queue.
      set(this, 'active', false);
      _get(this, 'queue')._addFiles(_get(this[DATA_TRANSFER], 'files'), 'drag-and-drop');
      this[DATA_TRANSFER] = null;
      evt.preventDefault();
      evt.stopPropagation();
    }
  });
});