define('ember-awesome-macros/array/is-any', ['exports', 'ember-macro-helpers/create-class-computed', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _createClassComputed, _computed, _normalizeArrayKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _createClassComputed.default)([false, true], function (array, key, value) {
    var args = [(0, _normalizeArrayKey.default)(array, [key])];
    if (value) {
      args.push(value);
    }
    return _computed.default.apply(undefined, args.concat([function (array) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (array) {
        var _EmberA;

        return (_EmberA = Ember.A(array)).isAny.apply(_EmberA, [key].concat(args));
      }
    }]));
  });
});