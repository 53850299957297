define('ember-awesome-macros/array/invoke', ['exports', 'ember-macro-helpers/create-class-computed', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _createClassComputed, _computed, _normalizeArrayKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _createClassComputed.default)([false, true], function (array, methodName, args) {
    return (0, _computed.default)((0, _normalizeArrayKey.default)(array, [methodName]), args, function (array, args) {
      if (array) {
        return Ember.A(array).invoke(methodName, args);
      }
    });
  });
});