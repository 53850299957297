define('ember-awesome-macros/array/-utils', ['exports', 'ember-macro-helpers/lazy-computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _lazyComputed, _normalizeArrayKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.normalizeArray = normalizeArray;
  exports.normalizeArray2 = normalizeArray2;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var sentinelValue = {};

  function normalizeArrayArgs(keys) {
    keys[0] = (0, _normalizeArrayKey.default)(keys[0]);
  }

  function normalizeArray(_ref, callback) {
    var _ref$defaultValue = _ref.defaultValue,
        defaultValue = _ref$defaultValue === undefined ? function () {
      return sentinelValue;
    } : _ref$defaultValue;

    return function () {
      for (var _len = arguments.length, keys = Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }

      normalizeArrayArgs(keys);

      return _lazyComputed.default.apply(undefined, keys.concat([function (get, array) {
        var arrayValue = get(array);
        if (!arrayValue) {
          var val = defaultValue();
          return val === sentinelValue ? arrayValue : val;
        }

        for (var _len2 = arguments.length, args = Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
          args[_key2 - 2] = arguments[_key2];
        }

        var values = args.map(get);
        return callback.call.apply(callback, [this, arrayValue].concat(_toConsumableArray(values)));
      }]));
    };
  }

  function normalizeArray2(funcStr) {
    return function () {
      for (var _len3 = arguments.length, keys = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        keys[_key3] = arguments[_key3];
      }

      normalizeArrayArgs(keys);
      return _lazyComputed.default.apply(undefined, keys.concat([function (get, arrayKey) {
        for (var _len4 = arguments.length, keys = Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {
          keys[_key4 - 2] = arguments[_key4];
        }

        var arrayVal = get(arrayKey);
        if (arrayVal === undefined) {
          return;
        }

        return arrayVal[funcStr].apply(arrayVal, _toConsumableArray(keys.map(get)));
      }]));
    };
  }
});