define('ember-awesome-macros/array/map-by', ['exports', 'ember-macro-helpers/create-class-computed', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _createClassComputed, _computed, _normalizeArrayKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _createClassComputed.default)([false, true], function (array, key) {
    return (0, _computed.default)((0, _normalizeArrayKey.default)(array, [key]), function (array) {
      if (!array || !key) {
        return array;
      }
      return Ember.A(array).mapBy(key);
    });
  });
});