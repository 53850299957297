define('ember-awesome-macros/array/uniq-by', ['exports', 'ember-macro-helpers/create-class-computed', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _createClassComputed, _computed, _normalizeArrayKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _createClassComputed.default)([false, true], function (array, key) {
    return (0, _computed.default)((0, _normalizeArrayKey.default)(array, [key]), function (array) {
      if (array === undefined || key === undefined) {
        return array;
      }

      if (!array.uniqBy) {
        // TODO: polyfill this
        // from https://github.com/emberjs/ember.js/blob/v2.11.0/packages/ember-runtime/lib/mixins/enumerable.js#L1094-L1105
        var ret = Ember.A();
        var seen = Object.create(null);

        array.forEach(function (item) {
          var guid = Ember.guidFor(Ember.get(item, key));
          if (!(guid in seen)) {
            seen[guid] = true;
            ret.push(item);
          }
        });

        return ret;
      }

      return array.uniqBy(key);
    });
  });
});